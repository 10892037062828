import * as React from 'react';
import { UnstyledLink as Link } from 'components/links';

export const Footer: React.FC = () => {
  return (
    <footer className='mt-12 text-gray-700'>
      <div className='skyline-footer h-16 w-full lg:h-24' />
      <div className='clear-both bg-green-700 px-4 py-8' />
      <div className='grid items-center justify-center gap-x-2 gap-y-2 bg-green-800 p-4 text-center text-sm text-stone-100 sm:flex'>
        <p>
          Copyright ©&nbsp;2023{' '}
          <Link
            href='https://www.chicagofairtrade.org'
            className='text-green-200 underline'
          >
            Chicago Fair Trade
          </Link>
        </p>
        <span className='hidden sm:block'>&bull;</span>
        <p>
          Made with love by{' '}
          <Link
            href='https://www.northxsouth.co'
            className='text-green-200 underline'
          >
            North X South
          </Link>
        </p>
      </div>
    </footer>
  );
};
