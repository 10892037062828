export const PRISMA_ERRORS = {
  P2002: {
    message: 'DUPLICATE',
  },
};

export type APIResponseError = {
  message?: string;
  meta?: {
    code?: string;
    target?: string[];
  };
  name?: string;
};

export class TokenExpiredError extends Error {
  constructor() {
    super('Token expired');
    this.name = 'TokenExpiredError';
  }
}
