import { useCanonicalHref } from 'lib/useCanonicalHref';
import { NextSeo, NextSeoProps } from 'next-seo';
import { useRouter } from 'next/router';

const defaults = {
  title: 'Open House Chicago Fair Trade',
  description: 'A World Fair Trade Day celebration in Chicago and the suburbs',
};

const defaultMeta: NextSeoProps = {
  title: defaults.title,
  description: defaults.description,
  titleTemplate: `%s | ${defaults.title}`,
  openGraph: {
    title: defaults.title,
    description: defaults.description,
    images: [{ url: '/static/social-image-banner@2x.png' }],
  },
  twitter: {
    handle: '@handle',
    site: '@chicagofair',
    cardType: 'summary_large_image',
  },
};

type SEOProps = NextSeoProps & {
  baseUrl?: string;
};

export const SEO: React.FC<SEOProps> = (props) => {
  const router = useRouter();
  const { baseUrl = process.env.BASE_URL ?? router.basePath, ...seoMeta } = {
    ...defaultMeta,
    ...props,
  };
  const { canonicalHref } = useCanonicalHref();

  seoMeta.canonical = canonicalHref;

  return <NextSeo {...seoMeta} />;

  // <meta name='robots' content={meta.robots} />
  // <meta content={meta.description} name='description' />
  // <meta property='og:url' content={canonicalHref} />
  // <link rel='canonical' href={canonicalHref} />
  // {/* Open Graph */}
  // <meta property='og:type' content={meta.type} />
  // <meta property='og:site_name' content={meta.siteName} />
  // <meta property='og:description' content={meta.description} />
  // <meta property='og:title' content={meta.title} />
  // {meta.image && (
  //   <meta name='image' property='og:image' content={meta.image} />
  // )}
  // {/* Twitter */}
  // <meta name='twitter:card' content='summary_large_image' />
  // <meta name='twitter:site' content='@chicagofair' />
  // <meta name='twitter:title' content={meta.title} />
  // <meta name='twitter:description' content={meta.description} />
  // {meta.image && <meta name='twitter:image' content={meta.image} />}
  // {meta.date && (
  //   <>
  //     <meta property='article:published_time' content={meta.date} />
  //     <meta
  //       name='publish_date'
  //       property='og:publish_date'
  //       content={meta.date}
  //     />
  //     <meta
  //       name='author'
  //       property='article:author'
  //       content='Chicago Fair Trade'
  //     />
  //   </>
  // )}

  // {/* Favicons */}
  // <link rel='shortcut icon' href='/favicon/favicon.svg' />
};
