import * as React from 'react';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';

export interface UseCanonicalHrefOptions {
  baseUrl?: string;
}

export const useCanonicalHref = (opts: UseCanonicalHrefOptions = {}) => {
  const { basePath, isReady } = useRouter();
  const { baseUrl = process.env.APP_URL ?? basePath } = opts;
  const [canonicalHref, setCanonicalHref] = React.useState<string>();

  React.useMemo(() => {
    async function getCanonicalHref() {
      if (isReady && typeof window !== 'undefined') {
        try {
          const href = new URL(window.location.pathname, baseUrl);
          setCanonicalHref(
            href.href.endsWith('/') ? href.href : `${href.href}/`
          );
        } catch (e) {
          Sentry.captureException(e);
        }
      }
    }
    // eslint-disable-next-line no-void
    void getCanonicalHref();
  }, [baseUrl, isReady]);

  return {
    canonicalHref,
  };
};
