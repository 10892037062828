import * as React from 'react';
import { clsxm } from 'lib/clsxm';
import { UnstyledLink, UnstyledLinkProps } from './UnstyledLink';
import { Variant } from './types';

type ButtonLinkProps = {
  isDarkBg?: boolean;
  isLoading?: boolean;
  variant?: keyof typeof Variant;
} & UnstyledLinkProps;

export const ButtonLink = React.forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (
    {
      children,
      className,
      variant = 'teal',
      isDarkBg = false,
      isLoading = false,
      ...rest
    },
    ref
  ) => {
    return (
      <UnstyledLink
        ref={ref}
        {...rest}
        className={clsxm(
          'font-semibold inline-flex items-center rounded px-4 py-2',
          'focus-visible:ring-primary-500 focus:outline-none focus-visible:ring',
          'cursor-pointer shadow-sm',
          'transition-colors duration-75',
          // #region  //*=========== Variants ===========
          [
            variant === 'primary' && [
              'bg-primary-500 text-white',
              'border-primary-600 border',
              'hover:bg-primary-600 hover:text-white',
              'active:bg-primary-500',
              'disabled:bg-primary-400 disabled:hover:bg-primary-400',
            ],
            variant === 'outline' && [
              'text-primary-500',
              'border-primary-500 border',
              'hover:bg-primary-50 active:bg-primary-100 disabled:bg-primary-100',
              isDarkBg &&
                'hover:bg-gray-900 active:bg-gray-800 disabled:bg-gray-800',
            ],
            variant === 'ghost' && [
              'text-primary-500',
              'shadow-none',
              'hover:bg-primary-50 active:bg-primary-100 disabled:bg-primary-100',
              isDarkBg &&
                'hover:bg-gray-900 active:bg-gray-800 disabled:bg-gray-800',
            ],
            variant === 'light' && [
              'text-dark bg-white ',
              'border border-gray-300',
              'hover:text-dark hover:bg-gray-100',
              'active:bg-white/80 disabled:bg-gray-200',
            ],
            variant === 'dark' && [
              'bg-gray-900 text-white',
              'border border-gray-600',
              'hover:bg-gray-800 active:bg-gray-700 disabled:bg-gray-700',
            ],
            variant === 'teal' && [
              'bg-teal-500 text-white',
              'border border-teal-500',
              'hover:bg-teal-600 hover:text-white',
              'active:bg-teal-500',
              'disabled:bg-teal-400 disabled:hover:bg-teal-400',
            ],
          ],
          // #endregion  //*======== Variants ===========
          'disabled:cursor-not-allowed',
          isLoading &&
            'relative text-transparent transition-none hover:text-transparent disabled:cursor-wait',
          className
        )}
      >
        {children}
      </UnstyledLink>
    );
  }
);
