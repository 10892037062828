import Link, { LinkProps } from 'next/link';
import * as React from 'react';
import { useRouter } from 'next/router';
import { clsxm } from 'lib';

export const useActivePath = ({
  href,
  matchPartial,
}: {
  href: string;
  matchPartial?: boolean;
}) => {
  const [isActive, setActive] = React.useState(false);
  const { asPath, isReady } = useRouter();

  React.useEffect(() => {
    if (isReady) {
      const linkPathname = new URL(href, window.location.href).pathname;
      const activePathname = new URL(asPath, window.location.href).pathname;

      const isRoot = href === '/';

      // Don't match partial paths if the href is a root path
      let shouldMatchPartial = !isRoot;

      // But if matchPartial is explicitly set to true or false, use that
      if (matchPartial !== undefined) {
        shouldMatchPartial = matchPartial;
      }

      const regex = shouldMatchPartial
        ? RegExp(`^${linkPathname}`)
        : RegExp(`^${linkPathname}/?$`);

      setActive(regex.test(activePathname));
    }
  }, [asPath, href, isReady, matchPartial]);

  return isActive;
};

export type ActiveLinkProps<T = React.ComponentPropsWithRef<'a'>> = {
  activeClassName?: string;
  href?: string | null;
  matchPartial?: boolean;
} & LinkProps &
  T;

export const ActiveLink = React.forwardRef<HTMLAnchorElement, ActiveLinkProps>(
  (
    {
      children,
      activeClassName,
      className: classNameProp,
      matchPartial,
      ...props
    },
    ref
  ) => {
    const { isReady } = useRouter();
    const [className, setClassName] = React.useState(classNameProp || '');

    const isActive = useActivePath({
      href: (props.as || props.href) as string,
      matchPartial,
    });

    React.useEffect(() => {
      if (isReady && isActive) {
        setClassName([classNameProp, activeClassName].join(' '));
      }
    }, [isReady, isActive, classNameProp, activeClassName]);

    const linkProps: ActiveLinkProps = {
      ...props,
      className: clsxm(className),
      'aria-current': isActive ? 'page' : undefined,
      // ensure it has a trailing slash
      href: props.href.endsWith('/') ? props.href : `${props.href}/`,
    };

    return (
      <Link {...linkProps} ref={ref}>
        {children}
      </Link>
    );
  }
);

export type UnstyledLinkProps<E = ActiveLinkProps> = {
  children: React.ReactNode;
  href?: string;
  internal?: boolean;
} & Omit<E, 'href'>;

export const UnstyledLink = React.forwardRef<
  HTMLAnchorElement,
  UnstyledLinkProps
>(({ children, internal, href = '', ...props }, ref) => {
  const internalLinkMatcher = /^\//g;

  if (!internal && href && internalLinkMatcher.test(href)) {
    const linkProps = {
      ...props,
      href,
    };

    return (
      <ActiveLink {...linkProps} ref={ref}>
        {children}
      </ActiveLink>
    );
  }

  const linkProps: React.ComponentPropsWithRef<'a'> = {
    ref,
    rel: 'noopener noreferrer',
    target: '_blank',
    href,
    ...props,
  };

  return <a {...linkProps}>{children}</a>;
});
