import { Transform, TransformFnParams } from 'class-transformer';
import { parseISO } from 'date-fns';
import phone from 'phone';

export const TransformDate = Transform(
  ({ value }) => {
    if (typeof value === 'string') {
      return parseISO(value);
    }
    if (typeof value === 'object') {
      return (value as Date).toString();
    }

    return value as unknown;
  },
  {
    toPlainOnly: true,
  }
);

export const TransformPhone = ({ value, ...rest }: TransformFnParams) => {
  const country = 'US';
  return phone(value as string, { country }).phoneNumber;
};
