import * as React from 'react';
import { Header } from './Header';
import { Footer } from './Footer';

export const Layout: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  // Put Header or Footer Here
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};
