import { faBars, faClose } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure } from '@headlessui/react';
import * as React from 'react';
import { UnstyledLink as Link } from 'components/links';
import LogoWideGreen from 'assets/images/wide-logo-green.svg';
import { clsxm } from 'lib';

export const Header: React.FC = () => {
  const navigation: Record<string, string | boolean>[] = [
    { name: 'Home', href: '/' },
    { name: 'About', href: '/about/' },
    { name: 'Raffle', href: '/raffle/' },
  ];

  return (
    <Disclosure as='nav' className='relative z-50 text-gray-800'>
      {({ open }) => (
        <>
          <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
            <div className='flex h-16 items-center justify-between'>
              <div className='flex items-center'>
                <div className='flex-shrink-0'>
                  <Link href='/'>
                    <LogoWideGreen className='w-full' />
                  </Link>
                </div>
                <div className='hidden md:block'>
                  <div className='ml-10 flex items-baseline space-x-4'>
                    {navigation.map((item) => (
                      <Link
                        key={item.name as string}
                        href={item.href as string}
                        className='font-medium rounded-md px-3 py-2 text-sm text-gray-800 hover:bg-green-700 hover:text-white'
                        activeClassName='bg-green-700 text-white'
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>

              <div className='-mr-2 flex md:hidden'>
                {/* Mobile menu button */}
                <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-gray-800 hover:border-gray-800 hover:bg-gray-100 hover:text-gray-800 focus:outline-none'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <FontAwesomeIcon
                      icon={faClose}
                      className='block h-6 w-6'
                      aria-hidden='true'
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faBars}
                      className='block h-6 w-6'
                      aria-hidden='true'
                    />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='md:hidden'>
            <div className='space-y-1 px-2 pb-3 pt-2 sm:px-3'>
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name as string}
                  as='a'
                  href={item.href as string}
                  className={clsxm(
                    'text-teal-800 hover:bg-teal-400 hover:text-white',
                    'font-medium block rounded-md px-3 py-2 text-base'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
