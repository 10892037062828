import * as React from 'react';
import { clsxm } from '../../lib/clsxm';
import { UnstyledLink, UnstyledLinkProps } from './UnstyledLink';

export const PrimaryLink = React.forwardRef<
  HTMLAnchorElement,
  UnstyledLinkProps
>(({ className, children, ...rest }, ref) => {
  return (
    <UnstyledLink
      ref={ref}
      {...rest}
      className={clsxm(
        'inline-flex items-center',
        'font-medium text-teal-500 underline hover:text-teal-300',
        'focus:outline-none focus-visible:rounded focus-visible:ring focus-visible:ring-teal-300 focus-visible:ring-offset-2',
        className
      )}
    >
      {children}
    </UnstyledLink>
  );
});
